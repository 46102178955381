import React, {
    useMemo,
    FunctionComponent,
    ReactElement,
    HTMLAttributes,
} from "react";
import { Container, Grid } from "@mui/material";
import clsx from "clsx";
import { MAIN_NET_ID } from "@liveart/nft-client/dist/chainId";

import { Token } from "~/api/data-schema";
import { SkeletonCard } from "~/features/artwork/card";

import { Card } from "./Card";
import { ImageContainer } from "../card/ImageContainer";
import { PriceContainer } from "../card/PriceContainer";
import { isStorybook } from "~/features/env";

interface Properties extends HTMLAttributes<HTMLElement> {
    tokens: Token[];
}

export const Cards: FunctionComponent<Properties> = ({
    children,
    className,
    tokens,
    ...rest
}: Properties): ReactElement => {
    const skeleton: number[] = useMemo(
        () => Array.from({ length: 12 }, (_: unknown, i: number): number => i),
        [],
    );

    return (
        <Container className={clsx(className)} {...rest}>
            <Grid container spacing={6} wrap="wrap">
                {tokens.length
                    ? tokens.map((token: Token) => (
                          <Grid key={token.tokenId} item xs={12} sm={12} md={3}>
                              <Card
                                  token={token}
                                  optimized
                                  blockchainId={MAIN_NET_ID}
                              >
                                  <PriceContainer />
                                  <ImageContainer />
                              </Card>
                          </Grid>
                      ))
                    : skeleton.map((key: number) => (
                          <Grid key={key} item xs={12} sm={12} md={3}>
                              <SkeletonCard
                                  animation={isStorybook() ? false : "wave"}
                                  width="100%"
                                  height={400}
                                  size={200}
                                  style={{ margin: "0" }}
                              />
                          </Grid>
                      ))}
            </Grid>
        </Container>
    );
};
