import {
    FunctionComponent,
    AnchorHTMLAttributes,
    ReactElement,
    useMemo,
    ReactNode,
    cloneElement,
    Children,
    isValidElement,
    HTMLAttributes,
} from "react";
import Link from "next/link";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";

import { styled } from "@mui/system";
import { Token } from "~/api/data-schema";
import { useTokenMarketDataFromToken } from "~/features/tokenMarketData";
import { ArtworkMedia } from "~/features/artwork";
import { EtherIcon } from "~/features/icon/EtherIcon";
import { formatPrice } from "~/features/formatters";
import { generatePDPLink } from "~/features/PDP/generatePDPLink";

interface Properties extends AnchorHTMLAttributes<HTMLElement> {
    readonly token: Token;
    readonly optimized?: boolean;
    readonly blockchainId: number;
}

const Container = styled("a")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
});
const Media = styled("div")({
    display: "flex",
    width: "100%",
    background: "#f5f5f5",
    height: "374px",
});
const Metadata = styled("div")({
    marginTop: "8px",
});

export const Card: FunctionComponent<Properties> = ({
    children,
    token,
    optimized,
    href,
    blockchainId,
    className,
    ...rest
}: Properties): ReactElement => {
    const {
        upcomingAuction,
        activeAuction,
        endedAuction,
        buyNowPrice,
        buyNow,
        soldFor,
        highestBidder,
        highestBid,
        tokenWithMarketDataId,
    } = useTokenMarketDataFromToken(token);

    const metadata: { first: ReactNode; second: string } = useMemo(() => {
        if (upcomingAuction) {
            return {
                first: (
                    <>
                        Starting bid: <EtherIcon />
                        {formatPrice(highestBid)}
                    </>
                ),
                second: "",
            };
        }

        if (activeAuction) {
            if (highestBidder) {
                return {
                    first: (
                        <>
                            Current bid: <EtherIcon />
                            {formatPrice(highestBid)}
                        </>
                    ),
                    second: `Highest bidder: ${highestBidder}`,
                };
            }

            return {
                first: (
                    <>
                        Starting bid: <EtherIcon />
                        {formatPrice(highestBid)}
                    </>
                ),
                second: "",
            };
        }

        if (endedAuction) {
            if (highestBidder) {
                return {
                    first: (
                        <>
                            Winning bid: <EtherIcon />
                            {formatPrice(highestBid)}
                        </>
                    ),
                    second: `Winning bidder: ${highestBidder}`,
                };
            }

            return {
                first: <>No winner</>,
                second: "",
            };
        }

        if (buyNow) {
            return {
                first: (
                    <>
                        Price: <EtherIcon />
                        {formatPrice(buyNowPrice)}
                    </>
                ),
                second: "",
            };
        }

        if (soldFor) {
            return {
                first: (
                    <>
                        Sold for: <EtherIcon />
                        {formatPrice(soldFor)}
                    </>
                ),
                second: "",
            };
        }

        return {
            first: "",
            second: "",
        };
    }, [
        activeAuction,
        buyNow,
        buyNowPrice,
        highestBid,
        highestBidder,
        soldFor,
        upcomingAuction,
        endedAuction,
    ]);

    const getHrefURL = (pdpLink: string | undefined) => {
        return (
            pdpLink ||
            generatePDPLink({
                tokenId: Number(tokenWithMarketDataId),
                application: token.metadata.properties.application
                    ?.description as SupportedApplication,
                blockchainId,
                tokenContractAddress: token.tokenContractAddress as string,
            })
        );
    };

    return (
        <Link href={getHrefURL(href)} passHref>
            <Container className={className} {...rest}>
                <Media>
                    <ArtworkMedia
                        metadata={token.metadata}
                        width="100%"
                        optimized={optimized}
                        quality="low"
                    />
                </Media>

                <Metadata>{token.metadata.name}</Metadata>

                {Children.map<ReactNode, ReactNode>(children, (child) => {
                    if (isValidElement(child)) {
                        return cloneElement(child, {
                            href: getHrefURL(href),
                            first: metadata.first,
                            secont: metadata.second,
                        } as HTMLAttributes<unknown>);
                    }
                    return <></>;
                })}
            </Container>
        </Link>
    );
};
