import { Bid } from "./PriceContainer.css";

export function PriceContainer(props) {
    const { first, second } = props;

    return first || second ? (
        <Bid data-testid="price-container">
            {first && <div>{first}</div>}
            {second && <div>{second}</div>}
        </Bid>
    ) : (
        <></>
    );
}
