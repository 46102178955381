import Image from "next/image";
import { styled } from "@mui/system";
import avatar from "./avatar.png";

const Author = styled("div")({
    color: "#aaa",
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    "& img": {
        borderRadius: "12px",
    },
    "& > span": {
        marginRight: "8px !important",
    },
});

export function ImageContainer() {
    return (
        <Author data-testid="author-image">
            <Image
                width={24}
                height={24}
                src={avatar}
                alt="Paul Kneale"
                data-testid="author-image"
            />
            Paul Kneale
        </Author>
    );
}
